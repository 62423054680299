.menu_element{
    @apply h-full px-4 flex items-center text-black font-medium text-sm cursor-pointer;
}
.active_menu_element{
    @apply text-black h-full px-4 flex items-center bg-softprimary font-medium text-sm cursor-pointer;
}
.label-primary + label{
    @apply text-primary;
}

.link{
    @apply text-blue-700 hover:underline cursor-pointer hover:text-darkprimary;
}

/* Custom select */
.basic-multi-select .select__control {
    @apply rounded-lg border border-gray-300 min-h-[40px];
}

.select__input:focus{
    @apply border-primary;
}
.basic-multi-select .select__control--is-focused {
    border-color: #efc014;
    box-shadow: unset;
}
.basic-multi-select .select__indicators{
    display:none;
}
.select__control:hover, .select__control:active{
    border-color: #efc014!important;
}
.select__input{
    box-shadow:unset!important;
    border-width:0px!important;
    outline:0px!;
}
.select__menu{
    z-index:20!important;
}
.select__menu{
    @apply rounded-lg border-gray-300;
}
.select__menu .select__option:hover{
    @apply bg-softprimary;
}
.select__menu .select__option--is-selected{
    @apply bg-softprimary text-main;
}
.select__menu .select__option--is-focused{
    @apply bg-softprimary;
}
.select__menu .select__option, .select__dropdown-indicator{
    @apply cursor-pointer;
}
.select__input-container{
    @apply cursor-text;
}
.select__menu .select__option{
    @apply py-2 px-2 border-b;
}
.select__menu-list{
    padding:0px!important;
    margin:0px!important;
}
.floating-label-async-select .select__menu{
    min-width:300px;
}
.select__multi-value{
    background-color: #fbe79d!important;
    border-color: #D09011!important;
    border-width: 1px!important;
    border-radius: .25rem!important;
    padding: 0!important;
    /* @apply bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300; */
}
.select__multi-value__label{
    color: #222222!important;
    font-weight: 500!important;
    font-size: .9rem!important;
    line-height: 1rem!important;
    padding-bottom: .125rem!important;
    padding-top: .125rem!important;
    padding-left: .425rem!important;
    padding-right: .125rem!important;
}
.select__multi-value__remove svg{
    width: 15px!important;
    height: 15px!important;
    color: #222222!important;
}

.select__multi-value__remove:hover{
    background-color: unset!important;
}

.select__multi-value__remove svg:hover{
    background-color: #D09011!important;
    color: #ffffff!important;
    border-radius: 3px;
}

.select__placeholder{
    @apply text-gray-500 dark:text-gray-400 text-sm
}

.ql-flip{
    min-width:400px;
}
/* .basic-multi-select .select__multi-value {
    background-color: #ff45df;
    color: white;
}

.basic-multi-select .select__multi-value__label {
    color: white;
}

.basic-multi-select .select__multi-value__remove {
    color: white;
} */
/* /Custom select */

/* AG GRID */
/* div.ag-root .ag-cell-focus {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
} */

.react-datepicker__input-container input{
    @apply mt-0 block px-2.5 py-2.5 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-primary focus:outline-none focus:ring-0 focus:border-primary;
}
.react-datepicker__month-container{
    @apply inline-block rounded-lg bg-white p-4 shadow-lg dark:bg-gray-700;
}
.react-datepicker{
    border-width:0px!important;
}
.react-datepicker__triangle{
    @apply hidden;
}
.react-datepicker__header{
    border-bottom: 0px!important;
    padding: 0px!important;
    background-color: #ffffff!important;
}
.react-datepicker__day{
    line-height: 2.25rem!important;
    margin:0px!important;
    width: 100%!important;
}
.react-datepicker__day{
    @apply block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600 hover:rounded-lg;
}
.react-datepicker__day:hover{
    background-color: #fbe79d!important;
}
.react-datepicker__week{
    @apply grid w-64 grid-cols-7;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected:hover, .react-datepicker__day--selected:hover{
    background-color: #efc014!important;
    color:white!important;
}
.react-datepicker__day-names{
    @apply grid w-full grid-cols-7 mb-2;
}
.react-datepicker__day-name{
    margin:0px!important;
    width: 100%!important;
}
.react-datepicker__day-name{
    @apply h-6 text-center text-sm font-medium leading-6 text-gray-500 dark:text-gray-400;
}
.react-datepicker__month{
    @apply m-0;
}
.react-datepicker__current-month{
    padding: 0px!important;
    padding-bottom:20px!important;
    font-weight: 500;
}
.react-datepicker__navigation{
    top:12px;
}
.react-datepicker__navigation{
    @apply rounded-lg hover:bg-softprimary;
}
.react-datepicker__navigation:hover ::before{
    @apply border-darkprimary;
}
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before{
    border-color: rgb(17 24 39 / var(--tw-text-opacity));
}
.react-datepicker__navigation--previous{
    left: 18px;
}
.react-datepicker__navigation--next{
    right: 18px;
}
.react-datepicker__navigation-icon--previous::before {
    top: 9px;
    right: -4px;
}
.react-datepicker__navigation-icon--next::before {
    top: 9px;
    left: -4px;
}
.react-datepicker__close-icon::after{
    @apply bg-primary font-black;
}
.react-datepicker__close-icon::after{
    font-size: 14px!important;
    margin-top:-1px;
    padding:0px;
    padding-bottom: 1px;
}
.react-datepicker-popper{
    @apply z-50;
}
.react-datepicker-wrapper{
    @apply block;
}
.offer_quill .ql-editor{
    padding:0px!important;
}
.offerDetailElement ul{
    @apply ms-3 list-disc;
}
.offerDetailElement ul li{
    @apply text-sm font-medium text-gray-900;
}

.previewOfferDetailsList ul{
    @apply text-black list-disc list-inside dark:text-gray-400 ms-6;
}

.previewOfferWeightList ul{
    @apply text-black list-disc list-inside dark:text-gray-400;
}

.previewOfferAdditionalServices ul{
    @apply text-black list-disc list-inside dark:text-gray-400 ms-6;
}
.previewOfferAdditionalServices ul .ql-indent-1{
    @apply ms-6;
}
.previewOfferAdditionalServices ul .ql-indent-2{
    @apply ms-12;
}
.previewOfferAdditionalServices ul .ql-indent-3{
    @apply ms-16;
}
.floating-label-select {
    position: relative;
}
.floating-label-select .floating-label {
    @apply hidden text-gray-500;
}
.floating-label-select .floating-label.filled {
    @apply absolute dark:text-gray-400 font-medium block text-sm duration-100 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto;
}
.floating-label-select .floating-label.filled.focused{
    @apply text-primary;
}

.floating-label-datepicker {
    position: relative;
}
.floating-label-datepicker .floating-label {
    @apply hidden text-gray-500;
}
.floating-label-datepicker .floating-label.filled {
    @apply absolute dark:text-gray-400 font-medium block text-sm duration-100 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto;
}
.floating-label-datepicker .floating-label.filled.focused{
    @apply text-primary;
}

.floating-label-timepicker {
    position: relative;
}
.floating-label-timepicker input{
    box-shadow: none!important;
}
.floating-label-timepicker input{
    @apply px-3 py-2;
}
.floating-label-timepicker .MuiFormControl-root{
    @apply w-full border-none;
}
.floating-label-timepicker .MuiFormControl-root .MuiInputBase-root{
    @apply border-none;
}
.floating-label-timepicker fieldset{
    @apply border border-gray-300 rounded-md focus-within:border-primary hover:border-gray-400;
}
.floating-label-timepicker:hover fieldset,
.floating-label-timepicker:focus-within fieldset {
  @apply !border-primary;
}
.floating-label-timepicker .floating-label {
    @apply hidden text-gray-500;
}
.floating-label-timepicker .floating-label.filled {
    @apply absolute dark:text-gray-400 font-medium block text-sm duration-100 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto;
}
.floating-label-timepicker .floating-label.filled.focused{
    @apply text-primary;
}

.link-primary {
    @apply text-darkprimary underline dark:text-primary hover:no-underline;
}
/*  --- AG GRID ---  */
.activities .ag-header-cell, .activities .ag-header-group-cell{
    @apply px-[7px];
}
.activities .ag-cell{
    @apply px-[7px];
}
.clientModalView .modal_body{
 @apply py-0;
}
.clientModalView .modal_footer{
 @apply py-3;
}
.clientModalView > div[role="dialog"]:first-of-type > div:first-of-type{
    min-height: 95vh;
}
.clientModalView .activities{
    @apply px-0;
}
.activities .ag-sort-indicator-icon.ag-sort-order{
    @apply hidden;
}

.ql-editor-container .ql-toolbar.ql-snow:first-of-type{
    display: none;
}

.ql-picker-options{
    max-height: 200px;
    overflow: auto;
}

.mailModal .ql-editor{
    height: 150px;
    max-height: 250px;
    overflow: auto;
}

.customMovementButton{
    @apply px-2 py-1 text-xs font-semibold text-main bg-primary rounded-md shadow-sm hover:bg-darkprimary hover:text-white focus:outline-none focus:ring-2 focus:ring-softprimary focus:ring-opacity-50 transition-colors duration-200;
}
.agGrid-timepicker{
    @apply flex items-center justify-center h-full;
}
.agGrid-timepicker input {
    @apply px-1 py-0 focus:!ring-0 focus:!outline-0;
}
.agGrid-timepicker fieldset {
    @apply focus:!ring-0 focus:!outline-0 !border-0;
}
.agGrid-timepicker .MuiInputBase-root{
    @apply border-none px-0;    
}
.ag-cell-range-single-cell:has(.agGrid-timepicker){
    @apply !border-primary;
}
/* --- MODIFICARE VARIABILE AG GRID --- */
.tabel-agGrid {
    --ag-range-selection-border-color: #efc014!important;
    --ag-range-selection-background-color: #fbe79d!important;
}
.customBreadCrumb ol{
    position: relative;
    min-height: 35px;
    padding-top:0px!important;
    padding-bottom:0px!important;
}

.ag-cell {
    @apply border-x border-y-0 border-gray-300;
}

.butonCuMargineMinus > :nth-child(1){
    @apply mt-[-47px] ms-[50px];
}
.ag-react-container{
    @apply w-full;
}
.ag-paging-row-summary-panel, .ag-paging-description, .ag-paging-page-size{
    @apply hidden md:block;
}
.ag-header-cell:hover .ag-header-cell-menu-button, .ag-column-menu-visible .ag-header-cell-menu-button{
    display: block!important;
}
.ag-header-cell .ag-header-cell-menu-button{
    display: none;
}
.ag-cell{
    padding-left: 5px;
    padding-right: 5px;
}
.ag-cell-inline-editing{
    padding-left: 0px;
    padding-right: 0px;
}
.ag-header-cell{
    padding-left: 10px;
    /* padding-right: 5px; */
}

.email-content table {
    width: 100% !important;
    table-layout: fixed;
}
  
.email-content table td {
    vertical-align: top;
    word-wrap: break-word;
}
.Toastify__toast-theme--colored.Toastify__toast--warning{
    background-color: #e5980a!important;
}